$color: #504c8c;
$colorRgb: 80, 76, 140;

:root {
	--aa-primary-color-rgb: 80, 76, 140 !important;
}

.aa-Panel {
	z-index: 10;
}

.aa-SubmitButton {
	cursor: pointer;
}

.ais-Highlight-highlighted,
.ais-Snippet-highlighted {
	background-color: rgba($color, 0.1);
	color: $color;
	font-weight: bold;
}

.ais-Hits-item,
.ais-InfiniteHits-item {
	padding: 0 !important;
}

.ais-InfiniteHits-loadPrevious,
.ais-InfiniteHits-loadMore {
	&.ais-InfiniteHits-loadMore--disabled {
		display: none;
	}
}
