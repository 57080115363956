@import 'style';

@mixin header-link {
	color: #707070;
	line-height: 1;
	font-weight: 500;
	transition: all 0.25s ease-out;

	&:hover,
	&.is-active {
		color: $blue-midnight;
	}
}

.header {
	width: 100%;
	position: fixed;
	position: sticky;
	z-index: 2;
	top: 0;
	left: 0;
	padding: 25px 0;
	background-color: $background;
	box-shadow: 0 0 10px 5px rgba(black, 0.05);
	transition: all 0.25s ease-out;

	&.is-fixed {
		transform: translateY(-30px);
	}

	&-inner {
		transition: transform 0.25s ease-out;
	}

	&.is-fixed &-inner {
		transform: translateY(15px);
	}

	&.mod-home {
		background-color: #050111;

		@include media($desktop) {
			background-color: rgba(12, 12, 12);
		}

		&.is-fixed {
			background-color: $blue-midnight;
		}
	}

	&-row {
		display: flex;
		align-items: center;
		justify-content: space-between;

		&-left {
			flex: 1 1 auto;
			display: flex;
			align-items: center;
		}
		&-right {
			display: block;
		}
	}

	&-logo {
		position: relative;
		display: inline-block;
		vertical-align: middle;

		> div:first-child {
			vertical-align: top;
		}

		&-projectname {
			color: white;
			font-size: 16px;
			line-height: 1;
			min-width: 125px;
			position: absolute;
			left: 0;
			top: calc(100% + 10px);
			opacity: 1;
			transition: opacity 0.25s ease-out;

			.is-fixed & {
				pointer-events: none;
				opacity: 0;
			}
		}
	}
}

.mobile {
	@include media($desktop) {
		display: none;
	}

	&-hamburger {
		position: relative;
		display: block;
		width: 40px;
		height: 40px;

		&:before,
		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: 40px;
			height: 2px;
			background-color: $turquoise;
			transition: transform 0.25s ease-out;
		}

		&:before {
			transform: translateY(calc(-50% - 5px));
		}

		&:after {
			transform: translateY(calc(-50% + 5px));
		}
	}
}

.nav + .translation {
	margin-left: 35px;
}

.nav {
	display: none;

	@include media($desktop) {
		display: inline-block;
		vertical-align: middle;
		margin-left: 30px;
	}

	&-item {
		position: relative;
		display: inline-block;
		vertical-align: middle;

		&-link {
			@include header-link;
			display: inline-block;
			vertical-align: top;
			padding: 15px;

			&:hover,
			&.is-active {
				color: $blue-midnight;
			}

			.mod-home & {
				color: white;

				&:hover {
					color: $turquoise;
				}
			}
		}

		&-dropdown {
			display: none;
			min-width: 175px;
			position: absolute;
			z-index: 1;
			top: 100%;
			left: 0;

			&-link {
				@include header-link;
				color: white;
				display: block;
				padding: 15px;
				background-color: $hover;

				&:hover {
					color: white;
					background-color: $blue-midnight;
				}
			}
		}

		&.mod-dropdown:hover .nav-item {
			&-link {
				color: white;
				background-color: $blue-midnight;
			}

			&-dropdown {
				display: block;
			}
		}
	}
}

.autocomplete {
	position: relative;
	z-index: 1;
	width: 100%;
	max-width: 175px;
	margin: 0 15px;

	@include media($tablet) {
		margin-right: 0;
		margin-left: 30px;
	}

	@include media($large) {
		max-width: 220px;
	}
}

.translation {
	display: none;

	@include media($desktop) {
		display: inline-block;
		vertical-align: middle;
	}

	&-link {
		@include header-link;

		.mod-home & {
			color: white;

			&:hover {
				color: $turquoise;
			}
		}
	}
}
