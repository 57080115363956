@import 'style';

.layout {
	position: relative;
	min-height: 100vh;
	display: flex;
	flex-direction: column;

	&-top {
		flex: 1;
	}

	&-content {
		&:not(.mod-home) {
			padding-top: 50px;
		}
	}
}
