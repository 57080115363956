@import 'style';

.tools {
	position: fixed;
	top: 10px;
	right: 10px;
	z-index: 999;

	&-button {
		color: #4d5a6e;
		font-size: 14px;
		line-height: 1.4;
		display: block;
		padding: 5px 15px;
		text-align: center;
		background-color: white;
		box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
		transition: background 0.25s ease-out;

		&:hover {
			background-color: #e8eef4;
		}

		& + & {
			margin-top: 10px;
		}
	}
}
