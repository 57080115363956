@import 'style';

.mobilenav {
	@include media($desktop) {
		display: none;
	}
}

.top {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px 0;

	&-left {
		position: relative;

		&-logo {
			display: inline-block;
			vertical-align: middle;

			> div:first-child {
				vertical-align: top;
			}
		}
	}

	&-right {
		&-hamburger {
			position: relative;
			display: block;
			width: 40px;
			height: 40px;

			&:before,
			&:after {
				content: '';
				display: block;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				width: 40px;
				height: 2px;
				background-color: $turquoise;
				transition: transform 0.25s ease-out;
			}

			&:before {
				transform: rotate(45deg) translateY(-50%);
			}

			&:after {
				transform: rotate(-45deg) translateY(-50%);
			}
		}
	}
}

.menu {
	padding: 50px 0;

	&-item {
		& + & {
			margin-top: 35px;
		}

		&-link {
			color: white;
			font-size: 24px;
			line-height: 1.2;
			font-weight: 500;
			transition: color 0.25s ease-out;

			&.mod-back,
			&.is-active {
				color: $turquoise;
			}

			&-label,
			&-icon {
				display: inline-block;
				vertical-align: middle;
			}

			&-label + &-icon,
			&-icon + &-label {
				margin-left: 15px;
			}

			&-icon {
				font-size: 12px;
			}
		}
	}
}

.active {
	display: block;
}
