.icon {
	height: 1em;
	width: 1em;
	display: flex;

	&.mod-inline {
		display: inline-flex;
	}

	>svg {
		height: 100% !important;
		//width: auto !important;
	}

	svg,
	path:not(.raw) {
		fill: currentColor !important;
	}
}
