@import 'style';
@import url('https://use.typekit.net/ogq8uta.css');

html,
body {
	font-family: 'Agenda', Arial, Helvetica, sans-serif;
	color: $gray-dark;
	font-size: 16px;
	line-height: 24px;
	font-weight: 300;
	background-color: $background;
	scroll-padding-top: 100px;

	@include media($desktop) {
		font-size: 18px;
		line-height: 27px;
		scroll-padding-top: 110px;
	}
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

a {
	color: inherit;
	cursor: pointer;
	text-decoration: none;

	img {
		border: none;
	}
}

button,
input,
select,
textarea {
	font: inherit;
	border: none;
	border-radius: 0;
	background: none;

	&:focus {
		outline: 0;
	}
}

textarea {
	resize: vertical;
}

iframe {
	border: none;
}
