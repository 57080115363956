@import 'style';

.footer {
	font-size: 16px;
	line-height: 1;
	font-weight: 500;
	margin: 55px 0;

	&-row {
		@include media($ipad-port) {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}

	&-logo {
		&-img,
		&-text {
			display: inline-block;
			vertical-align: middle;
		}

		&-img {
			width: 47px;
			height: 50px;
		}

		&-text {
			margin-left: 15px;
		}
	}

	&-nav {
		margin-top: 25px;

		@include media($ipad-port) {
			margin-top: 0;
		}

		&-link {
			border-bottom: 1px solid transparent;
			transition: border 0.25s ease-out;

			&:hover {
				border-color: currentColor;
			}

			& + & {
				margin-left: 35px;
			}
		}
	}
}
