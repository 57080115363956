@import 'style';

.blocks {
	margin: 75px 0;
}

.block {
	& + & {
		margin-top: 75px;
	}

	& + .media,
	.media + & {
		margin-top: 50px;
	}

	& + .list,
	& + .imagecta,
	& + .imagelinks,
	& + .partners {
		@include media($desktop) {
			margin-top: 125px;
		}
	}
}
